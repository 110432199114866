const colors = {
  primary: '',
  accent: '#78C257',
  secondary: '',
  bodyBackgroundColor: '#fff',
  textColor: '#0B1A17',
  logoColor: '#000',
  lightGray: '#C5C7C6',
};

const button = {
  borderColor: '#0B1A17',
  textColor: '#0B1A17',
  hover: {
    bgColor: '#0B1A17',
    textColor: '#fff',
  },
};

export default {
  colors,
  button,
};
