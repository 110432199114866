// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-page-contact-jsx": () => import("./../../../src/templates/page-contact.jsx" /* webpackChunkName: "component---src-templates-page-contact-jsx" */),
  "component---src-templates-page-default-jsx": () => import("./../../../src/templates/page-default.jsx" /* webpackChunkName: "component---src-templates-page-default-jsx" */),
  "component---src-templates-services-default-jsx": () => import("./../../../src/templates/services-default.jsx" /* webpackChunkName: "component---src-templates-services-default-jsx" */)
}

