import React from 'react';
import {StyledThemeProvider} from './StyledThemeProvider';
import {ThemeManagerProvider} from './ThemeManager';
import lightTheme from './theme/light';
import darkTheme from './theme/dark';
import {GlobalStyle} from './globalStyles';
import './assets/styles/global.css';

export const wrapRootElement = (gatsbyRootProps) => {
  return (
    <ThemeManagerProvider>
      <StyledThemeProvider lightTheme={lightTheme} darkTheme={darkTheme}>
        <GlobalStyle />
        {gatsbyRootProps.element}
      </StyledThemeProvider>
    </ThemeManagerProvider>
  );
};
