import {createGlobalStyle} from 'styled-components';
export const GlobalStyle = createGlobalStyle`
  body {
    background-color: #F1F1F1;
    &.theme-dark {
      background-color: #030303;
    }
    transition: background-color .2s;
  }
`;
