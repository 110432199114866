const colors = {
  primary: '',
  accent: '#78C257',
  secondary: '',
  bodyBackgroundColor: '#030303',
  textColor: '#B4B5B7',
  logoColor: '#fff',
  lightGray: '#454545',
};

const button = {
  borderColor: '#B4B5B7',
  textColor: '#B4B5B7',
  hover: {
    bgColor: '#B4B5B7',
    textColor: '#000',
  },
};

export default {
  colors,
  button,
};
